<template>
  <fieldsTds
    v-if="type == 'SSH'"
    :fieldAttributes="{ ...fieldAttributes, type: 'select' }"
    :field="field"
    :templateContent="result"
    v-on="$listeners"
    :value="value"
  ></fieldsTds>
</template>
<script>
import fieldsTds from "@/commonComponents/fieldsTds.vue";
export default {
  components: { fieldsTds },
  props: ["result", "fieldAttributes", "field","value"],
  computed: {
    type() {
      return this.result.valueLine.c_type;
    },
  },
};
</script>